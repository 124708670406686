import * as React from "react";
import {PickerProps} from "./UnifiedCommon";

export class Picker extends React.Component<PickerProps, {}> {
  render() {
    return null;
  }

  static Item = {} as any;
}
