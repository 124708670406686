import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
const styles = require("./RadioButton.module.css");
import Box from "./Box";
import Label from "./Label";
import Text from "./Text";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  id: string;
  label?: string;
  name?: string;
  onChange: (arg0: {event: React.SyntheticEvent; checked: boolean}) => void;
  value: string;
  size?: "sm" | "md";
}

interface State {
  focused: boolean;
  hovered: boolean;
}

export default class RadioButton extends React.Component<Props, State> {
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["sm", "md"]),
  };

  static defaultProps = {
    checked: false,
    disabled: false,
    size: "md",
  };

  state = {
    focused: false,
    hovered: false,
  };

  handleChange = (event: React.SyntheticEvent) => {
    const {onChange} = this.props;
    const {checked} = event.target as any;
    onChange({checked, event});
  };

  handleBlur = () => this.setState({focused: false});

  handleFocus = () => this.setState({focused: true});

  handleHover = (hovered: boolean) => {
    this.setState({hovered});
  };

  render() {
    const {checked, disabled, id, label, name, size, value} = this.props;
    const {focused, hovered} = this.state;

    let borderStyle = styles.Border;
    if (!disabled && checked) {
      borderStyle = styles.BorderDarkGray;
    } else if (!disabled && hovered) {
      borderStyle = styles.BorderHovered;
    }

    let borderWidth = styles.BorderUnchecked;
    if (checked && size === "sm") {
      borderWidth = styles.BorderCheckedSm;
    } else if (checked && size === "md") {
      borderWidth = styles.BorderCheckedMd;
    }

    const styleSize = size === "sm" ? styles.SizeSm : styles.SizeMd;

    const bgStyle = disabled && !checked ? styles.BgDisabled : styles.BgEnabled;

    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="start"
        marginLeft={-1}
        marginRight={-1}
      >
        <Label htmlFor={id}>
          <Box paddingX={1}>
            <div
              className={classnames(
                bgStyle,
                borderStyle,
                borderWidth,
                styleSize,
                styles.RadioButton,
                {
                  [styles.RadioButtonIsFocused]: focused,
                }
              )}
            >
              <input
                checked={checked}
                className={classnames(styles.Input, styleSize, {
                  [styles.InputEnabled]: !disabled,
                })}
                disabled={disabled}
                id={id}
                name={name}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover(false)}
                type="radio"
                value={value}
              />
            </div>
          </Box>
        </Label>

        {label && (
          <Label htmlFor={id}>
            <Box paddingX={1}>
              <Text color={disabled ? "gray" : undefined} size={size === "sm" ? "md" : "lg"}>
                {label}
              </Text>
            </Box>
          </Label>
        )}
      </Box>
    );
  }
}
