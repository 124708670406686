import React from "react";
import {NewsItem} from "../../Common";
import {Box, Spinner, withProfile, WithProfileProps} from "../../unified";
import {NewsColumn} from "./NewsColumn";
import {withNewsItems, WithNewsItems} from "./Store";

interface NewsContainerProps extends WithNewsItems, WithProfileProps {
  componentId: string;
  newsItemId: string;
}

interface NewsContainerState {}

export class NewsContainer extends React.Component<NewsContainerProps, NewsContainerState> {
  static options = {
    topBar: {
      title: {text: ""},
      visible: true,
      backButton: {visible: true},
    },
  };

  constructor(props: NewsContainerProps) {
    super(props);
    this.state = {};
  }

  getItem = (oldProps?: NewsContainerProps): NewsItem | undefined => {
    const props = oldProps || this.props;

    if (!props.newsItems.docs) {
      return undefined;
    }
    const item: NewsItem = props.newsItems.docs[props.newsItemId];

    if (!props.profile || !item) {
      return undefined;
    }
    return item;
  };

  render() {
    const newsItems = this.props.newsItems.docs || {};
    if (!this.props.profile.doc || Object.keys(newsItems).length === 0) {
      return (
        <Box display="flex" flex="grow" justifyContent="center" alignItems="center">
          <Spinner size="md" />
        </Box>
      );
    }
    return (
      <Box height="100%" width="100%" display="flex" overflow="scrollY" color="lightGray">
        <NewsColumn
          componentId={this.props.componentId}
          newsItemId={this.props.newsItemId}
          profile={this.props.profile.doc}
        />
      </Box>
    );
  }
}

export const NewsPage = withProfile(withNewsItems(NewsContainer));
