import * as React from "react";
import {BaseProfile, Box, FireStorm, Heading, SignUpForm, Text, Unifier} from "unified";

interface AuthPageProps {
  componentId: string;
  profile: Partial<BaseProfile>;
}

export class AuthPage extends React.Component<AuthPageProps, {}> {
  static options = () => ({
    topBar: {
      visible: false,
      // visible: false,
      // animate: false,o
    },
    bottomBar: {
      visible: false,
    },
  });

  finish = async (shouldGoToPayment: boolean) => {
    // if (shouldGoToPayment) {
    //   Unifier.navigation.goToPayment();
    // } else {
    Unifier.navigation.goToMain();
    // }
  };

  render() {
    const profile = FireStorm.getUserOrSignupData();
    return (
      // <ImageBackground source={getOnboardingBackground()} style={{width: "100%", height: "100%"}}>
      <Box
        display="flex"
        height="100%"
        width="100%"
        paddingY={2}
        paddingX={2}
        avoidKeyboard={true}
        // justifyContent="center"
        alignItems="center"
        color="primary"
        direction="column"
        overflow="scroll"
      >
        <Box
          paddingY={4}
          display="flex"
          direction="row"
          height={80}
          minHeight={80}
          maxWidth={400}
          alignSelf="center"
          justifyContent="center"
          width="100%"
        >
          <Box marginRight={4}>
            <img src="/logo192.png" alt="idleinbox logo" style={{width: 44, height: 40}} />
            {/* <Icon size={30} prefix="fas" name="mail-bulk" color="white" /> */}
          </Box>
          <Heading color="white" align="center">
            Idle Inbox
          </Heading>
        </Box>
        <Box maxWidth={400} paddingY={2} marginBottom={4} paddingX={4}>
          <Text color="white" align="center" size="lg" bold={true}>
            Keep your email clutter-free! Idle Inbox is your private inbox for news, newsletters,
            and feeds. A beautiful and simple way to read what's important to you.
          </Text>
        </Box>

        <Box maxWidth={400} paddingY={1} paddingX={4}>
          <Text color="white" align="center">
            When you sign up, you'll receive an @IdleInbox.com email. Subscribe to newsletters using
            that email. Then read them during your idle time in Idle Inbox.
          </Text>
        </Box>
        {/* <Box maxWidth={400} paddingY={1} paddingX={4}>
          <Text color="white" align="center">
            Trying to get to Inbox Zero? Forward emails from your normal email to IdleInbox to read
            later!
          </Text>
        </Box> */}

        {/* <BlurBox paddingY={3}> */}
        <Box maxWidth={400} width="100%">
          <SignUpForm
            // title="Idle Inbox"
            // image={image}
            // explainer="Lastly, to finish creating your account:"
            componentId={this.props.componentId}
            profileData={profile}
            finish={this.finish}
          />
        </Box>
        {/* </BlurBox> */}

        <Box height={100} minHeight={100}>
          <p>&nbsp;</p>
        </Box>
      </Box>
      // </ImageBackground>
    );
  }
}
