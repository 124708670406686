/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import {Layout, Options, LayoutRoot} from "./navigation";
import {
  NavConfig,
  TrackerInterface,
  TrackingProperties,
  LogLevel,
  PermissionKind,
  PermissionStatus,
  BaseProfile,
  TrackingConfig,
} from "./UnifiedCommon";

export interface UnifiedTheme {
  primaryLighterColor: string;
  primaryLightColor: string;
  primaryColor: string;
  primaryDarkColor: string;
  primaryDarkerColor: string;

  secondaryLighterColor: string;
  secondaryLightColor: string;
  secondaryColor: string;
  secondaryDarkColor: string;
  secondaryDarkerColor: string;

  accentLighterColor: string;
  accentLightColor: string;
  accentColor: string;
  accentDarkColor: string;
  accentDarkerColor: string;

  tertiaryLighterColor: string;
  tertiaryLightColor: string;
  tertiaryColor: string;
  tertiaryDarkColor: string;
  tertiaryDarkerColor: string;

  neutral900: string;
  neutral800: string;
  neutral700: string;
  neutral600: string;
  neutral500: string;
  neutral400: string;
  neutral300: string;
  neutral200: string;
  neutral100: string;
  neutral90: string;
  neutral80: string;
  neutral70: string;
  neutral60: string;
  neutral50: string;
  neutral40: string;
  neutral30: string;
  neutral20: string;
  neutral10: string;
}

const DefaultTheme: UnifiedTheme = {
  primaryLighterColor: "#4ED456",
  primaryLightColor: "#28CA32",
  primaryColor: "#00BD0C",
  primaryDarkColor: "#00960A",
  primaryDarkerColor: "#007508",

  secondaryLighterColor: "#41AAAE",
  secondaryLightColor: "#20989D",
  secondaryColor: "#018B91",
  secondaryDarkColor: "#016E73",
  secondaryDarkerColor: "#00565A",

  accentLighterColor: "#FF625D",
  accentLightColor: "#FF3732",
  accentColor: "#F00600",
  accentDarkColor: "#BE0500",
  accentDarkerColor: "#950400",

  tertiaryLighterColor: "#FFCF67",
  tertiaryLightColor: "#FFC23E",
  tertiaryColor: "#FFB109",
  tertiaryDarkColor: "#CA8A00",
  tertiaryDarkerColor: "#9F6D00",

  // From the Atlassian templates
  neutral900: "#091E42",
  neutral800: "#172B4D",
  neutral700: "#253858",
  neutral600: "#344563",
  neutral500: "#42526E",
  neutral400: "#505F79",
  neutral300: "#5E6C84",
  neutral200: "#6B778C",
  neutral100: "#7A869A",
  neutral90: "#8993A4",
  neutral80: "#97A0AF",
  neutral70: "#A5ADBA",
  neutral60: "#B3BAC5",
  neutral50: "#C1C7D0",
  neutral40: "#DFE1E6",
  neutral30: "#EBECF0",
  neutral20: "#F4F5F7",
  neutral10: "#FAFBFC",
};

export type UnifiedLayout = Layout;
export type UnifiedLayoutOptions = Options;
export type UnifiedLayoutRoot = LayoutRoot;

export interface UnifiedUtils {
  dismissKeyboard: () => void;
  dimensions: () => {height: number; width: number};
  copyToClipboard: (text: string) => void;
  requestPermissions: (permission: PermissionKind) => Promise<PermissionStatus>;
  makePurchase: (profile: any, sku: any) => void;
  PaymentService: any;
  vibrate: (pattern?: number[]) => void;
  haptic: () => void;
  openUrl: (url: string) => Promise<void>;
}

const StubUtils: UnifiedUtils = {
  dismissKeyboard: () => {},
  dimensions: () => {
    console.warn("[unifier] STUB DIMENSIONS");
    return {height: 1, width: 1};
  },
  copyToClipboard: (text: string) => {},
  requestPermissions: async (permission: PermissionKind) => "denied",
  makePurchase: (profile: any, sku: any) => {},
  PaymentService: {},
  vibrate: (pattern?: number[]) => {},
  haptic: () => {},
  openUrl: async (url: string) => {},
};

const StubTracking: TrackerInterface = {
  initFinished: true,
  init: (config: TrackingConfig) => {
    console.warn("Initializing stub tracking.");
  },
  trackPages: () => {},
  setUser: (user: BaseProfile) => {},
  setUserProperty: (property: string, value: string | object) => {},
  track: (eventName: string, properties?: TrackingProperties) => {},
  trackNavigation: (screen: string, properties?: TrackingProperties) => {},
  trackLogin: (method: string, success: boolean, properties?: TrackingProperties) => {},
  log: (message: string, properties?: TrackingProperties, level?: LogLevel) => {},
  error: (message: string, properties?: TrackingProperties) => {},
  debug: (message: string, properties?: TrackingProperties) => {},
  warn: (message: string, properties?: TrackingProperties) => {},
  trackSignup: (method: string, success: boolean, properties?: TrackingProperties) => {},
  handleErrorAlert: (text: string, exception?: Error, showAlert?: boolean) => {},
  trackPermission: (kind: PermissionKind, status: PermissionStatus, requested: boolean) => {},
  updateAppInfo: () => {},
};

const StubNav = {
  bindComponent: (component: React.Component<any>, componentId?: string) => {},
  registerScreen: (
    componentName: string,
    component: React.ComponentType<any>,
    config?: NavConfig
  ) => {
    throw new Error("Tried to register with stub.");
  },
  registerActionSheet: (
    componentName: string,
    component: React.ComponentType<any>,
    config?: NavConfig
  ) => {
    throw new Error("Tried to register action sheet with stub.");
  },
  setRoot: async (layout: UnifiedLayoutRoot) => {},
  setDefaultOptions: (options: UnifiedLayoutOptions) => {},
  mergeOptions: (componentId: string, layout: UnifiedLayoutOptions) => {},
  push: async (componentId: string, passProps?: any) => {},
  pop: async (componentId: string) => {},
  popToRoot: async (componentId: string) => {},
  showOverlay: async (layout: UnifiedLayout) => {},
  dismissOverlay: async (componentId: string) => {},
  showModal: async (layout: UnifiedLayout) => {},
  dismissModal: async (componentId: string) => {},
  dismissAllModals: async () => {},
  goToAuth: () => {},
  goToMain: () => {},
  goToPayment: () => {},
  // TODO figure out something better here.
  clearNotificationsForTab: (tab: "log" | "feed" | "chat" | "profile") => {},
};

export interface UnifiedNavigation {
  bindComponent: (t: any) => void;
  registerScreen: (
    componentName: string,
    component: React.ComponentType<any>,
    config?: NavConfig
  ) => void;
  registerActionSheet: (
    componentName: string,
    component: React.ComponentType<any>,
    config?: NavConfig
  ) => void;
  setRoot: (layout: UnifiedLayoutRoot) => Promise<any>;
  setDefaultOptions: (options: UnifiedLayoutOptions) => void;
  mergeOptions: (componentId: string, layout: UnifiedLayoutOptions) => void;
  push: (componentId: string, passProps?: any) => Promise<any>;
  pop: (componentId: string) => Promise<any>;
  popToRoot: (componentId: string) => Promise<any>;
  showOverlay: (layout: UnifiedLayout) => Promise<any>;
  dismissOverlay: (componentId: string) => Promise<any>;
  showModal: (layout: UnifiedLayout) => Promise<any>;
  dismissModal: (componentId: string) => Promise<any>;
  dismissAllModals: () => Promise<any>;
  goToAuth: () => void;
  goToMain: () => void;
  goToPayment: () => void;
  // TODO figure out something better here.
  clearNotificationsForTab: (tab: "log" | "feed" | "chat" | "profile") => void;
}

export interface AppConfig {
  web?: boolean;
  dev?: boolean;
  theme?: UnifiedTheme;
  navigation: UnifiedNavigation;
  tracking: TrackerInterface;
  utils: UnifiedUtils;
}

class UnifierClass {
  private _nav?: UnifiedNavigation;
  private _theme?: UnifiedTheme;
  private _tracking?: TrackerInterface;
  private _utils?: UnifiedUtils;
  private _web = false;
  private _dev = false;

  get web(): boolean {
    return this._web;
  }

  get dev(): boolean {
    return this._dev;
  }

  get theme(): UnifiedTheme {
    return this._theme || DefaultTheme;
  }

  get navigation(): UnifiedNavigation {
    if (!this._nav) {
      // throw new Error("[unifier] You must call setConfig before using navigation.");
      return StubNav;
    }
    return this._nav;
  }

  get tracking(): TrackerInterface {
    if (!this._tracking) {
      // throw new Error("[unifier] You must call setConfig before using tracking.");
      return StubTracking;
    }
    return this._tracking;
  }

  get utils(): UnifiedUtils {
    if (!this._utils) {
      // throw new Error("[unifier] You must call setConfig before using navigation.");
      return StubUtils;
    }
    // if (!this._utils) {
    //   throw new Error("[unifier] You must call setConfig before using utils.");
    // }
    return this._utils;
  }

  constructor() {
    console.debug("[unifier] Setting up Unifier");
  }

  initIcons = () => {
    console.debug("[unifier] Initializing icons");
  };

  setConfig(config: Partial<AppConfig>) {
    // console.debug("[unifier] Setting config", config);
    if (config.theme) {
      this._theme = config.theme;
    }
    this._utils = config.utils;
    this._nav = config.navigation;
    this._tracking = config.tracking;
    this._web = Boolean(config.web);
    this._dev = Boolean(config.dev);
  }
}

export const Unifier = new UnifierClass();

export function navPush(componentId: string, screen: string, passProps?: any) {
  Unifier.navigation.push(componentId, {
    component: {
      name: screen,
      passProps,
    },
  });
}
