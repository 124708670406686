import debounce from "lodash/debounce";
import * as React from "react";
import {Box} from "./Box";
import {Icon} from "./Icon";
import {Spinner} from "./Spinner";
import {Text} from "./Text";
import {ButtonProps, Color, COLOR_MAP, iconSizeToNumber} from "./UnifiedCommon";
import {Unifier} from "./Unifier";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ButtonState {
  loading: boolean;
}

const buttonTextColor: {[buttonColor: string]: "white" | "darkGray"} = {
  blue: "white",
  lightGray: "darkGray",
  red: "white",
  transparent: "white",
  white: "darkGray",
  primary: "white",
  secondary: "white",
  accent: "white",
  tertiary: "white",
  facebook: "white",
  twitter: "white",
  google: "white",
};

export class Button extends React.Component<ButtonProps, ButtonState> {
  state = {loading: false};
  HEIGHTS = {
    sm: 36,
    md: 40,
    lg: 48,
  };

  getBackgroundColor(color: string) {
    if (this.props.type === "ghost" || this.props.type === "outline") {
      return "transparent";
    } else {
      return COLOR_MAP[color];
    }
  }

  getTextColor(color: Color): Color {
    if (this.props.disabled) {
      return "gray";
    } else if (this.props.type === "ghost" || this.props.type === "outline") {
      return color;
    } else {
      return buttonTextColor[color] || "white";
    }
  }

  getBorderColor(color: string) {
    if (this.props.type === "outline") {
      return COLOR_MAP[this.getTextColor(color as Color)];
    } else {
      return "transparent";
    }
  }

  renderContent() {
    let color = this.props.color || "lightGray";
    if (color === "gray") {
      color = "lightGray";
    }

    if (this.props.children) {
      if (this.props.icon || this.props.text) {
        console.warn("Buttons with children and icons or text are not supported.");
      }
      return this.props.children;
    }

    return (
      <Box paddingX={4} display="flex" direction="row">
        {this.props.icon !== undefined && (
          <Box paddingX={2}>
            <Icon
              prefix={this.props.iconPrefix || "far"}
              size={iconSizeToNumber(this.props.size)}
              name={this.props.icon}
              color={this.getTextColor(this.props.color as Color)}
            />
          </Box>
        )}
        {Boolean(this.props.text) && (
          <Text
            bold={true}
            color={this.getTextColor(color as Color)}
            size={this.props.size}
            skipLinking={true}
          >
            {this.props.text}
          </Text>
        )}
        {(this.state.loading || this.props.loading) && (
          <Box marginLeft={2}>
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              spin={true}
              color={COLOR_MAP[this.getTextColor(this.props.color as Color)] || "#666"}
              size="1x"
            />
          </Box>
        )}
      </Box>
    );
  }

  render() {
    let color = this.props.color || "lightGray";
    if (color === "gray") {
      color = "lightGray";
    }

    return (
      <Box width={this.props.inline === true ? undefined : "100%"}>
        <div
          style={{
            height: this.HEIGHTS[this.props.size || "md"],
            backgroundColor: this.getBackgroundColor(color),
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            borderColor: this.getBorderColor(color),
            borderWidth: this.props.type === "outline" ? 2 : 0,
            opacity: this.props.disabled ? 0.7 : 1,
            flexDirection: "row",
            display: "flex",
            cursor: "pointer",
          }}
          // disabled={this.props.disabled || this.props.loading}
          onClick={debounce(
            async () => {
              if (this.props.disabled || this.props.loading) {
                return;
              }
              Unifier.utils.haptic();
              this.setState({loading: true});
              try {
                if (this.props.onClick) {
                  await this.props.onClick();
                }
              } catch (e) {
                this.setState({loading: false});
                throw e;
              }
              this.setState({loading: false});
            },
            500,
            {leading: true}
          )}
        >
          {this.renderContent()}
        </div>
      </Box>
    );
  }
}
