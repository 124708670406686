import * as React from "react";
import PropTypes from "prop-types";
import Box from "./Box";
import Icon from "./Icon";
import Text from "./Text";
import Touchable from "./Touchable";
import VideoPlayhead from "./VideoPlayhead";
const styles = require("./Video.module.css");

interface Props {
  accessibilityMaximizeLabel: string;
  accessibilityMinimizeLabel: string;
  accessibilityMuteLabel: string;
  accessibilityPauseLabel: string;
  accessibilityPlayLabel: string;
  accessibilityUnmuteLabel: string;
  currentTime: number;
  duration: number;
  fullscreen: boolean;
  onFullscreenChange: () => void;
  onPause: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onPlay: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onPlayheadDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  onPlayheadUp: (event: React.MouseEvent<HTMLDivElement>) => void;
  onVolumeChange: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  playing: boolean;
  seek: (time: number) => void;
  volume: number;
}

const fullscreenEnabled = () =>
  document.fullscreenEnabled || // $FlowIssue - vendor prefix missing from Flow
  (document as any).webkitFullscreenEnabled || // $FlowIssue - vendor prefix missing from Flow
  (document as any).mozFullScreenEnabled || // $FlowIssue - vendor prefix missing from Flow
  (document as any).msFullscreenEnabled;

const timeToString = (time?: number) => {
  const rounded = Math.floor(time || 0);
  const minutes = Math.floor(rounded / 60);
  const seconds = rounded - minutes * 60;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutesStr}:${secondsStr}`;
};

class VideoControls extends React.Component<Props> {
  static propTypes = {
    accessibilityMaximizeLabel: PropTypes.string.isRequired,
    accessibilityMinimizeLabel: PropTypes.string.isRequired,
    accessibilityMuteLabel: PropTypes.string.isRequired,
    accessibilityPauseLabel: PropTypes.string.isRequired,
    accessibilityPlayLabel: PropTypes.string.isRequired,
    accessibilityUnmuteLabel: PropTypes.string.isRequired,
    currentTime: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    fullscreen: PropTypes.bool.isRequired,
    onFullscreenChange: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    onPlayheadDown: PropTypes.func.isRequired,
    onPlayheadUp: PropTypes.func.isRequired,
    onVolumeChange: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    seek: PropTypes.func.isRequired,
    volume: PropTypes.number.isRequired,
  };

  handleFullscreenChange = ({
    event,
  }: {
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;
  }) => {
    const {onFullscreenChange} = this.props;
    event.stopPropagation();
    onFullscreenChange();
  };

  handlePlayingChange = ({
    event,
  }: {
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;
  }) => {
    const {playing, onPause, onPlay} = this.props;
    if (playing) {
      onPause(event);
    } else {
      onPlay(event);
    }
  };

  handleVolumeChange = ({
    event,
  }: {
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;
  }) => {
    const {onVolumeChange} = this.props;
    onVolumeChange(event);
  };

  render() {
    const {
      accessibilityMaximizeLabel,
      accessibilityMinimizeLabel,
      accessibilityMuteLabel,
      accessibilityPauseLabel,
      accessibilityPlayLabel,
      accessibilityUnmuteLabel,
      currentTime,
      duration,
      fullscreen,
      onPlayheadDown,
      onPlayheadUp,
      playing,
      seek,
      volume,
    } = this.props;
    const muted = volume === 0;
    const showFullscreenButton = typeof document !== "undefined" && !!fullscreenEnabled();
    return (
      <div className={styles.controls}>
        <Box padding={2}>
          <Touchable onTouch={this.handlePlayingChange} fullWidth={false}>
            <Icon
              accessibilityLabel={playing ? accessibilityPauseLabel : accessibilityPlayLabel}
              color="white"
              icon={playing ? "pause" : "play"}
              size={20}
            />
          </Touchable>
        </Box>
        <Box width={50} padding={2}>
          <Text color="white" align="right" size="sm">
            {timeToString(currentTime)}
          </Text>
        </Box>
        <Box padding={2} flex="grow">
          <VideoPlayhead
            currentTime={currentTime}
            duration={duration}
            onPlayheadDown={onPlayheadDown}
            onPlayheadUp={onPlayheadUp}
            seek={seek}
          />
        </Box>
        <Box width={50} padding={2}>
          <Text color="white" align="right" size="sm">
            {timeToString(duration)}
          </Text>
        </Box>
        <Box padding={2}>
          <Touchable onTouch={this.handleVolumeChange} fullWidth={false}>
            <Icon
              accessibilityLabel={muted ? accessibilityUnmuteLabel : accessibilityMuteLabel}
              color="white"
              icon={muted ? "mute" : "sound"}
              size={20}
            />
          </Touchable>
        </Box>
        {showFullscreenButton && (
          <Box padding={2}>
            <Touchable onTouch={this.handleFullscreenChange} fullWidth={false}>
              <Icon
                accessibilityLabel={
                  fullscreen ? accessibilityMinimizeLabel : accessibilityMaximizeLabel
                }
                color="white"
                icon={fullscreen ? "minimize" : "maximize"}
                size={20}
              />
            </Touchable>
          </Box>
        )}
      </div>
    );
  }
}

export default VideoControls;
