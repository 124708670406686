import {simpleParser} from "mailparser";
import React from "react";
import {Collections, NewsItem, Profile} from "../../Common";
import {Box, FireStorm, Heading, Text, Unifier} from "../../unified";
import {getDateString} from "./Utilities";

interface NewsColumnProps {
  componentId: string;
  profile: Profile;
  newsItemId: string;
}

interface NewsColumnState {
  content?: any;
  item?: NewsItem;
}

export class NewsColumn extends React.Component<NewsColumnProps, NewsColumnState> {
  constructor(props: NewsColumnProps) {
    super(props);
    this.state = {content: undefined, item: undefined};
  }

  async componentDidMount() {
    const item = await FireStorm.getDocument(Collections.newsItems, this.props.newsItemId);

    if (item) {
      this.setState({item: item as NewsItem});
      this.onItemUpdate(item as NewsItem);
    }
  }

  async componentDidUpdate(oldProps: NewsColumnProps) {
    if (!this.state.item) {
      const item = await FireStorm.getDocument(Collections.newsItems, this.props.newsItemId);
      this.setState({item: item as NewsItem});
      this.onItemUpdate(item as NewsItem);
    }
  }

  onItemUpdate = async (item: NewsItem) => {
    this.setState({content: undefined});
    Unifier.navigation.mergeOptions(this.props.componentId, {
      topBar: {
        title: {text: item.from},
      },
    });
    const content = (await simpleParser(item.content)).html;
    this.setState({content});
    await this.markRead(item);
  };

  markRead = async (item: NewsItem) => {
    await FireStorm.updateDocument(Collections.newsItems, item.id, {read: true});
  };

  renderEmail() {
    const item = this.state.item;
    if (!item) {
      return null;
    }
    if (this.state.content) {
      // const parse = ReactHtmlParser(item.html);
      // return parse;
      return <div dangerouslySetInnerHTML={{__html: this.state.content}} />;
    } else {
      const splitText = (item.text || "").split("\n");
      return splitText.map((text, i) => (
        <Box width="100%" paddingY={1} key={i}>
          <Text>{text}</Text>
        </Box>
      ));
    }
  }

  renderContent() {
    const item = this.state.item;
    if (!item) {
      return null;
    }
    return (
      <Box display="flex" flex="grow" direction="column" paddingY={8} width="100%">
        <Box paddingY={2} display="none" mdDisplay="block">
          <Heading align="center">{item.subject}</Heading>
        </Box>
        <Box paddingY={2} display="block" mdDisplay="none">
          <Heading align="center" size="sm">
            {item.subject}
          </Heading>
        </Box>
        <Box paddingY={1}>
          <Text bold={true} align="center">
            {getDateString(item)}
          </Text>
        </Box>
        {this.renderEmail()}
      </Box>
    );
  }

  render() {
    return (
      <Box
        width="100%"
        color="white"
        padding={0}
        mdPadding={2}
        shape="rounded"
        className="news-column"
      >
        {this.renderContent()}
      </Box>
    );
  }
}
