import React from "react";
import {Collections, NewsItem} from "../../Common";
import {Box, FireStorm, Text} from "../../unified";
import {IconButton} from "../../unified/";
import {Favicon} from "./Favicon";
import {getDateString, getSnippet} from "./Utilities";

interface NewsCardProps {
  small: boolean;
  item: NewsItem;
  componentId: string;
  selected?: boolean;
  onClick: () => void;
}

interface NewsCardState {}

export class NewsCard extends React.Component<NewsCardProps, NewsCardState> {
  constructor(props: NewsCardProps) {
    super(props);
    this.state = {};
  }

  render() {
    const {item} = this.props;
    if (!item) {
      return null;
    }

    return (
      <Box
        key={item.id}
        border={this.props.selected ? "blue" : undefined}
        marginBottom={4}
        shape="rounded"
        color={item.read ? "white" : "white"}
        paddingY={2}
        paddingX={4}
        onClick={() => this.props.onClick()}
      >
        <Box paddingY={2} display="flex" direction="row">
          <Box
            width={50}
            marginRight={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Favicon item={item} />
          </Box>
          <Box flex="grow">
            <Box>
              <Text size="lg" color={item.read ? "gray" : "darkGray"} bold={true}>
                {item.from}
              </Text>
            </Box>
            <Box>
              <Text size="md" color={item.read ? "gray" : "darkGray"} bold={true}>
                {item.subject}
              </Text>
            </Box>
            <Box>
              {item.created && (
                <Text size="md" color={item.read ? "gray" : "darkGray"}>
                  {getDateString(item)}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
        <Box paddingY={2}>
          <Text size="md">{getSnippet(this.props.item, this.props.small ? 250 : 100)}</Text>
        </Box>

        <Box display="flex" direction="row" paddingY={2} alignItems="center">
          <Box width={100}>
            <Text size="md" underline={true}>
              Read More
            </Text>
          </Box>
          <Box flex="grow">&nbsp;</Box>
          <Box
            width={90}
            display="flex"
            // mdDisplay="flex"
            direction="row"
            justifyContent="around"
          >
            <IconButton
              prefix="fas"
              bgColor="lightGray"
              icon={item.read ? "envelope-open" : "envelope"}
              onClick={() =>
                FireStorm.updateDocument(Collections.newsItems, item.id, {read: !item.read})
              }
              iconColor={item.read ? "darkGray" : "blue"}
              accessibilityLabel="mark read"
            />
            <IconButton
              prefix="fas"
              bgColor="lightGray"
              icon={item.deleted ? "trash-restore-alt" : "trash-alt"}
              onClick={() =>
                FireStorm.updateDocument(Collections.newsItems, item.id, {deleted: !item.deleted})
              }
              iconColor="red"
              accessibilityLabel="delete"
            />
          </Box>
        </Box>

        {/* <div
            dangerouslySetInnerHTML={{
              __html: item.html && item.html.replace(/(<? *script)/gi, "illegalscript"),
            }}
          /> */}
      </Box>
    );
  }
}
