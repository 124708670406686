import React from "react";
import {Suggestion} from "../../Common";
import {Card, Page, Text, UnifiedLayoutOptions} from "../../unified";
import {WithSuggestions, withSuggestions} from "./Store";

interface SuggestionCardProps {
  suggestion: Suggestion;
}

interface SuggestionCardState {}

export class SuggestionCard extends React.Component<SuggestionCardProps, SuggestionCardState> {
  constructor(props: SuggestionCardProps) {
    super(props);
    this.state = {};
  }

  render() {
    const {suggestion} = this.props;
    return (
      <Card>
        <Text>{suggestion.name}</Text>
      </Card>
    );
  }
}

interface SuggestionsProps extends WithSuggestions {}

interface SuggestionsState {}

class SuggestionsPageBare extends React.Component<SuggestionsProps, SuggestionsState> {
  static options: UnifiedLayoutOptions = {
    topBar: {
      title: {text: "Suggested Newsletters"},
      backButton: {
        visible: false,
      },
    },
  };

  constructor(props: SuggestionsProps) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("PROPS", this.props);
    return (
      <Page componentId="">
        {Object.values(this.props.suggestions.docs || {}).map((s) => (
          <SuggestionCard suggestion={s} />
        ))}
      </Page>
    );
  }
}

export const SuggestionsPage = withSuggestions(SuggestionsPageBare);
