import ad from "./ad.svg";
import add from "./add.svg";
import addCircle from "./add-circle.svg";
import addLayout from "./add-layout.svg";
import addPin from "./add-pin.svg";
import adGroup from "./ad-group.svg";
import adsStats from "./ads-stats.svg";
import adsOverview from "./ads-overview.svg";
import alert from "./alert.svg";
import alignBottom from "./align-bottom.svg";
import alignBottomCenter from "./align-bottom-center.svg";
import alignBottomLeft from "./align-bottom-left.svg";
import alignBottomRight from "./align-bottom-right.svg";
import alignMiddle from "./align-middle.svg";
import alignTop from "./align-top.svg";
import alignTopCenter from "./align-top-center.svg";
import alignTopLeft from "./align-top-left.svg";
import alignTopRight from "./align-top-right.svg";
import angledPin from "./angled-pin.svg";
import apps from "./apps.svg";
import arrowBack from "./arrow-back.svg";
import arrowCircleDown from "./arrow-circle-down.svg";
import arrowCircleForward from "./arrow-circle-forward.svg";
import arrowCircleUp from "./arrow-circle-up.svg";
import arrowDown from "./arrow-down.svg";
import arrowEnd from "./arrow-end.svg";
import arrowForward from "./arrow-forward.svg";
import arrowStart from "./arrow-start.svg";
import arrowUp from "./arrow-up.svg";
import arrowUpRight from "./arrow-up-right.svg";
import bell from "./bell.svg";
import calendar from "./calendar.svg";
import camera from "./camera.svg";
import cameraRoll from "./camera-roll.svg";
import cancel from "./cancel.svg";
import canonicalPin from "./canonical-pin.svg";
import check from "./check.svg";
import checkCircle from "./check-circle.svg";
import circleOutline from "./circle-outline.svg";
import clear from "./clear.svg";
import clock from "./clock.svg";
import cog from "./cog.svg";
import colorPicker from "./color-picker.svg";
import compass from "./compass.svg";
import compose from "./compose.svg";
import conversionTag from "./conversion-tag.svg";
import creditCard from "./credit-card.svg";
import crop from "./crop.svg";
import dash from "./dash.svg";
import directionalArrowLeft from "./directional-arrow-left.svg";
import directionalArrowRight from "./directional-arrow-right.svg";
import download from "./download.svg";
import duplicate from "./duplicate.svg";
import edit from "./edit.svg";
import ellipsis from "./ellipsis.svg";
import ellipsisCircleOutline from "./ellipsis-circle-outline.svg";
import envelope from "./envelope.svg";
import eye from "./eye.svg";
import facebook from "./facebook.svg";
import faceHappy from "./face-happy.svg";
import faceSad from "./face-sad.svg";
import faceSmiley from "./face-smiley.svg";
import fileUnknown from "./file-unknown.svg";
import fillOpaque from "./fill-opaque.svg";
import fillTransparent from "./fill-transparent.svg";
import filter from "./filter.svg";
import flag from "./flag.svg";
import flash from "./flash.svg";
import flashlight from "./flashlight.svg";
import flipHorizontal from "./flip-horizontal.svg";
import flipVertical from "./flip-vertical.svg";
import folder from "./folder.svg";
import gif from "./gif.svg";
import globe from "./globe.svg";
import globeChecked from "./globe-checked.svg";
import gmail from "./gmail.svg";
import googlePlus from "./google-plus.svg";
import graphBar from "./graph-bar.svg";
import handle from "./handle.svg";
import handPointing from "./hand-pointing.svg";
import heart from "./heart.svg";
import heartOutline from "./heart-outline.svg";
import heartBroken from "./heart-broken.svg";
import impressum from "./impressum.svg";
import infoCircle from "./info-circle.svg";
import key from "./key.svg";
import knoop from "./knoop.svg";
import lightbulb from "./lightbulb.svg";
import lightningBoltCircle from "./lightning-bolt-circle.svg";
import link from "./link.svg";
import location from "./location.svg";
import lock from "./lock.svg";
import logoLarge from "./logo-large.svg";
import logoSmall from "./logo-small.svg";
import logout from "./logout.svg";
import marginsLarge from "./margins-large.svg";
import marginsMedium from "./margins-medium.svg";
import marginsSmall from "./margins-small.svg";
import maximize from "./maximize.svg";
import megaphone from "./megaphone.svg";
import menu from "./menu.svg";
import minimize from "./minimize.svg";
import move from "./move.svg";
import mute from "./mute.svg";
import overlayText from "./overlay-text.svg";
import pause from "./pause.svg";
import people from "./people.svg";
import person from "./person.svg";
import personAdd from "./person-add.svg";
import phone from "./phone.svg";
import pin from "./pin.svg";
import pinHide from "./pin-hide.svg";
import pinterest from "./pinterest.svg";
import play from "./play.svg";
import questionMark from "./question-mark.svg";
import refresh from "./refresh.svg";
import remove from "./remove.svg";
import reorderImages from "./reorder-images.svg";
import replace from "./replace.svg";
import report from "./report.svg";
import rotate from "./rotate.svg";
import scale from "./scale.svg";
import search from "./search.svg";
import security from "./security.svg";
import send from "./send.svg";
import share from "./share.svg";
import shoppingBag from "./shopping-bag.svg";
import smiley from "./smiley.svg";
import smileyOutline from "./smiley-outline.svg";
import sound from "./sound.svg";
import sortAscending from "./sort-ascending.svg";
import sortDescending from "./sort-descending.svg";
import speech from "./speech.svg";
import speechEllipsis from "./speech-ellipsis.svg";
import star from "./star.svg";
import switchAccount from "./switch-account.svg";
import tag from "./tag.svg";
import terms from "./terms.svg";
import textAlignCenter from "./text-align-center.svg";
import textAlignLeft from "./text-align-left.svg";
import textAlignRight from "./text-align-right.svg";
import textAllCaps from "./text-all-caps.svg";
import textExtraSmall from "./text-extra-small.svg";
import textLarge from "./text-large.svg";
import textLineHeight from "./text-line-height.svg";
import textMedium from "./text-medium.svg";
import textSentenceCase from "./text-sentence-case.svg";
import textSize from "./text-size.svg";
import textSmall from "./text-small.svg";
import textSpacing from "./text-spacing.svg";
import trashCan from "./trash-can.svg";
import twitter from "./twitter.svg";
import videoCamera from "./video-camera.svg";
import viewTypeDefault from "./view-type-default.svg";
import viewTypeDense from "./view-type-dense.svg";
import viewTypeList from "./view-type-list.svg";
import workflowStatusAll from "./workflow-status-all.svg";
import workflowStatusHalted from "./workflow-status-halted.svg";
import workflowStatusInProgress from "./workflow-status-in-progress.svg";
import workflowStatusOk from "./workflow-status-ok.svg";
import workflowStatusProblem from "./workflow-status-problem.svg";
import workflowStatusUnstarted from "./workflow-status-unstarted.svg";
import workflowStatusWarning from "./workflow-status-warning.svg";

export default {
  ad,
  "ad-group": adGroup,
  add,
  "add-circle": addCircle,
  "add-layout": addLayout,
  "add-pin": addPin,
  "ads-stats": adsStats,
  "ads-overview": adsOverview,
  alert,
  "align-bottom-center": alignBottomCenter,
  "align-bottom-left": alignBottomLeft,
  "align-bottom-right": alignBottomRight,
  "align-bottom": alignBottom,
  "align-middle": alignMiddle,
  "align-top-center": alignTopCenter,
  "align-top-left": alignTopLeft,
  "align-top-right": alignTopRight,
  "align-top": alignTop,
  "angled-pin": angledPin,
  apps,
  "arrow-back": arrowBack,
  "arrow-circle-down": arrowCircleDown,
  "arrow-circle-forward": arrowCircleForward,
  "arrow-circle-up": arrowCircleUp,
  "arrow-down": arrowDown,
  "arrow-end": arrowEnd,
  "arrow-forward": arrowForward,
  "arrow-start": arrowStart,
  "arrow-up": arrowUp,
  "arrow-up-right": arrowUpRight,
  bell,
  calendar,
  camera,
  "camera-roll": cameraRoll,
  cancel,
  "canonical-pin": canonicalPin,
  "color-picker": colorPicker,
  check,
  "check-circle": checkCircle,
  "circle-outline": circleOutline,
  clear,
  clock,
  cog,
  compass,
  compose,
  crop,
  dash,
  "conversion-tag": conversionTag,
  "credit-card": creditCard,
  "directional-arrow-left": directionalArrowLeft,
  "directional-arrow-right": directionalArrowRight,
  download,
  duplicate,
  edit,
  ellipsis,
  "ellipsis-circle-outline": ellipsisCircleOutline,
  envelope,
  eye,
  facebook,
  "face-happy": faceHappy,
  "face-sad": faceSad,
  "face-smiley": faceSmiley,
  "file-unknown": fileUnknown,
  "fill-opaque": fillOpaque,
  "fill-transparent": fillTransparent,
  filter,
  flag,
  flash,
  flashlight,
  flipHorizontal,
  flipVertical,
  folder,
  gif,
  globe,
  "globe-checked": globeChecked,
  gmail,
  "google-plus": googlePlus,
  "graph-bar": graphBar,
  handle,
  "hand-pointing": handPointing,
  heart,
  "heart-outline": heartOutline,
  "heart-broken": heartBroken,
  impressum,
  "info-circle": infoCircle,
  key,
  knoop,
  lightbulb,
  "lightning-bolt-circle": lightningBoltCircle,
  link,
  location,
  lock,
  "logo-large": logoLarge,
  "logo-small": logoSmall,
  logout,
  "margins-large": marginsLarge,
  "margins-medium": marginsMedium,
  "margins-small": marginsSmall,
  maximize,
  megaphone,
  menu,
  minimize,
  move,
  mute,
  "overlay-text": overlayText,
  pause,
  people,
  person,
  "person-add": personAdd,
  phone,
  pin,
  "pin-hide": pinHide,
  pinterest,
  play,
  refresh,
  "question-mark": questionMark,
  remove,
  "reorder-images": reorderImages,
  replace,
  report,
  rotate,
  scale,
  search,
  security,
  "shopping-bag": shoppingBag,
  smiley,
  "smiley-outline": smileyOutline,
  send,
  share,
  sound,
  "sort-ascending": sortAscending,
  "sort-descending": sortDescending,
  speech,
  "speech-ellipsis": speechEllipsis,
  star,
  "switch-account": switchAccount,
  tag,
  terms,
  "text-align-left": textAlignLeft,
  "text-align-center": textAlignCenter,
  "text-align-right": textAlignRight,
  "text-all-caps": textAllCaps,
  "text-extra-small": textExtraSmall,
  "text-large": textLarge,
  "text-line-height": textLineHeight,
  "text-medium": textMedium,
  "text-sentence-case": textSentenceCase,
  "text-size": textSize,
  "text-small": textSmall,
  "text-spacing": textSpacing,
  "trash-can": trashCan,
  twitter,
  "video-camera": videoCamera,
  "view-type-default": viewTypeDefault,
  "view-type-dense": viewTypeDense,
  "view-type-list": viewTypeList,
  "workflow-status-all": workflowStatusAll,
  "workflow-status-halted": workflowStatusHalted,
  "workflow-status-in-progress": workflowStatusInProgress,
  "workflow-status-ok": workflowStatusOk,
  "workflow-status-problem": workflowStatusProblem,
  "workflow-status-unstarted": workflowStatusUnstarted,
  "workflow-status-warning": workflowStatusWarning,
};
