import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import memoize from "memoize-one";
import React from "react";
import {Collections, NewsItem, Profile} from "../../Common";
import {
  Box,
  FireStorm,
  FlatList,
  Heading,
  Page,
  Text,
  UnifiedLayoutOptions,
  Unifier,
  withProfile,
  WithProfileProps,
} from "../../unified";
import {NewsCard} from "./NewsCard";
import {NewsColumn} from "./NewsColumn";

interface HomePageProps extends WithProfileProps {
  componentId: string;
}

interface HomePageState {
  page: number;
  newsItemId?: string;
  loading: boolean;
  items: NewsItem[];
}

class HomePageBare extends React.Component<HomePageProps, HomePageState> {
  static options: UnifiedLayoutOptions = {
    topBar: {
      title: {text: "Your News"},
      backButton: {
        visible: false,
      },
    },
  };

  constructor(props: HomePageProps) {
    super(props);
    this.state = {loading: false, items: [], page: 0};
  }

  componentDidMount() {
    this.fetchItems(0, this.props.profile.doc);
  }

  componentDidUpdate(prevProps: HomePageProps) {
    const profile = this.props.profile.doc;

    if (!prevProps.profile.doc && this.props.profile.doc) {
      this.fetchItems(0, this.props.profile.doc);
    }
    if (localStorage && profile && localStorage.getItem("profile") !== profile.id) {
      localStorage.setItem("profile", profile.id);
    }
  }

  fetchItems = memoize(async (page: number, profile: Profile) => {
    if (!profile) {
      return;
    }
    let items;

    this.setState({loading: true});
    let startAfterId;
    if (this.state.items && this.state.items[this.state.items.length - 1]) {
      startAfterId = this.state.items && (this.state.items[this.state.items.length - 1] as any).id;
    }
    // const PAGE_SIZE = Unifier.web ? 100 : 20;
    const PAGE_SIZE = 20;

    items = await FireStorm.getDocuments({
      collection: Collections.newsItems,
      query: ["ownerId", "==", this.props.profile.doc.id],
      limit: PAGE_SIZE,
      startAfterId,
    });

    this.setState({
      page,
      items: [...(this.state.items || []), ...Object.values(items)],
      loading: false,
    });
  });

  renderEmpty() {
    const profile = (this.props.profile.doc as Profile) || FireStorm.getUser();
    return (
      <Box display="flex" flex="grow" justifyContent="center" alignItems="center">
        <Box>
          <Box paddingY={4}>
            <Heading size="sm" align="center">
              Nothing here yet.
            </Heading>
          </Box>
          <Box paddingY={2}>
            <Text align="center">Sign up for newsletters using your IdleInbox email:</Text>
          </Box>
          <Box paddingY={4}>
            <Text size="lg" bold={true} align="center">
              {profile ? `${profile.username}@idleinbox.com` : "unknown.."}
            </Text>
          </Box>
          <Box paddingY={2}>
            <Text align="center">And they'll all show up here!</Text>
          </Box>
        </Box>
      </Box>
    );
  }

  renderDetail() {
    const newsItems = {};
    for (let item of this.state.items) {
      newsItems[item.id] = item;
    }
    if (!newsItems || !this.state.newsItemId) {
      return (
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
          <FontAwesomeIcon
            icon={["far", "newspaper"]}
            color="#666"
            size="10x"
            style={{justifySelf: "center", alignSelf: "center"}}
          />
        </Box>
      );
    }
    return (
      <Box width="100%">
        <NewsColumn
          profile={this.props.profile.doc}
          componentId={this.props.componentId}
          newsItemId={this.state.newsItemId}
        />
      </Box>
    );
  }

  renderCard = (data: any, small: boolean) => {
    const n = data.item as NewsItem;
    return (
      <NewsCard
        key={n.id}
        item={n}
        componentId={this.props.componentId}
        selected={this.state.newsItemId === n.id}
        small={small}
        onClick={() => {
          if (small) {
            Unifier.navigation.push(this.props.componentId, {
              component: {
                name: "News",
                passProps: {
                  newsItemId: n.id,
                },
              },
            });
          } else {
            this.setState({newsItemId: n.id});
          }
        }}
      />
    );
  };

  renderCards(small = true) {
    return (
      <Box width="100%">
        {this.state.items.filter((i) => !i.deleted).map((n) => this.renderCard({item: n}, small))}
      </Box>
    );
  }

  renderFlatList(small: boolean) {
    return (
      <FlatList
        style={{flex: 1, width: "100%", height: "100%"}}
        data={this.state.items}
        renderItem={(data) => this.renderCard(data, small)}
        keyExtractor={(item: any, index: number) => item.id + index}
        onEndReached={() => {
          this.fetchItems(this.state.page + 1, this.props.profile.doc);
        }}
        onEndReachedThreshold={0.3}
        numColumns={1}
        // columnWrapperStyle={{flexShrink: 0}}

        // paddingX={2}
        // dangerouslySetInlineStyle={{
        //   __style: {
        //     flexShrink: 0,
        //   },
        // }}
      />
    );
  }

  render() {
    if (!this.state.items || !this.state.items.length) {
      return (
        <Page componentId="home" color="lightGray">
          {this.renderEmpty()}
        </Page>
      );
    }
    return (
      <Page componentId="home" color="lightGray" maxWidth="100%">
        <Box width="100%" height="100%" direction="row" display="flex" justifyContent="center">
          {/* Small screen */}
          <Box mdDisplay="none" display="flex" direction="column" overflow="scrollY">
            {this.renderFlatList(true)}
          </Box>
          {/* Large screen left */}
          <Box
            display="none"
            mdDisplay="flex"
            direction="column"
            maxWidth={300}
            paddingX={2}
            height="100%"
            dangerouslySetInlineStyle={{
              __style: {
                flexShrink: 0,
              },
            }}
          >
            {this.renderFlatList(false)}
          </Box>
          {/* Large screen detail */}
          <Box
            display="none"
            mdDisplay="flex"
            direction="column"
            flex="grow"
            maxWidth={800}
            paddingX={2}
            overflow="scrollY"
          >
            {this.renderDetail()}
          </Box>
        </Box>
      </Page>
    );
  }
}

export const HomePage = withProfile(HomePageBare);
