import "firebase/analytics";
import * as firebaseApp from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import React from "react";
import {BrowserRouter} from "react-router-dom";
import {FireStorm} from "../../unified";
// import logo from "./logo.svg";
import "./App.css";
import {Navigator} from "./Navigator";

export const Config = {
  SENTRY_MOBILE_DSN: "https://ac1f9cc240264e8893ff79e85236bb2f@o106257.ingest.sentry.io/5361189",
  SENTRY_WEB_DSN: "https://ac1f9cc240264e8893ff79e85236bb2f@o106257.ingest.sentry.io/5361189",
  MIXPANEL_TOKEN: "9aad6901ef454c095fc0fc344e3817e2",
  FEEDBACK_URL:
    "https://docs.google.com/forms/d/e/1FAIpQLSdgXUoqeDr8nJaubjDZ5mvbMqpZrzO816RSh1P6yA3UVx36HA/viewform?usp=sf_link",
  PROFILE_COLLECTION: "profiles",
  PRIVACY_POLICY_URL: "https://www.nang.io/avo-toast-privacy-policy",
  TERMS_URL: "https://www.nang.io/avo-toast-terms-of-service/",
};

let INITIALIZED = false;

// const COLORS = {
//   "Imperial Red": "e63946",
//   Honeydew: "f1faee",
//   "Powder Blue": "a8dadc",
//   "Celadon Blue": "457b9d",
//   "Prussian Blue": "1d3557",
// };

var firebaseConfig = {
  apiKey: "AIzaSyBXGm2BU6u8brpE0wO6P7WCml0tntaKYew",
  authDomain: "news-dbb91.firebaseapp.com",
  databaseURL: "https://news-dbb91.firebaseio.com",
  projectId: "news-dbb91",
  storageBucket: "news-dbb91.appspot.com",
  messagingSenderId: "148955991114",
  appId: "1:148955991114:web:f4dd735b43da6f39e8eab3",
  measurementId: "G-ZLDX8VKD2H",
};

function initializeFirebase() {
  console.debug("Initializing Firebase for the web");
  if (INITIALIZED) {
    return firebaseApp;
  }
  try {
    firebaseApp.initializeApp(firebaseConfig);
  } catch (err) {
    if (err.code === "app/duplicate-app") {
      return firebaseApp;
    }
    console.error("Error initializing Firebase", err);
    throw err;
  }
  INITIALIZED = true;

  firebaseApp.firestore();
  return firebaseApp;
}

const firebase = initializeFirebase();
FireStorm.init(firebase as any);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Navigator />
      </BrowserRouter>
    );
  }
}

export default App;
