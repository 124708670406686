import React from "react";
import {NewsItem} from "../../Common";

interface FaviconProps {
  item: NewsItem;
}

interface FaviconState {}

export class Favicon extends React.Component<FaviconProps, FaviconState> {
  render() {
    if (!this.props.item || !this.props.item.fromAddress) {
      return null;
    }
    let fromDomain;
    try {
      let split = this.props.item.fromAddress.split("@")[1].split(".");
      fromDomain = split.slice(split.length - 2).join(".");
    } catch (e) {
      console.warn("Error generating domain for favicon", this.props.item.fromAddress, e);
    }

    return (
      <>
        {fromDomain && (
          <img
            style={{width: 32, height: 32}}
            alt=""
            src={`https://s2.googleusercontent.com/s2/favicons?sz=64&domain=${fromDomain}`}
          />
        )}
      </>
    );
  }
}
