import {ModelProps, FireStormModel} from "../../unified";
import {Collections, NewsItem, Suggestion} from "../../Common";

export interface WithNewsItems {
  newsItems: ModelProps<NewsItem>;
  // saveFoodLog: (document: any) => void;
}

export const withNewsItems = (WrappedComponent: React.ComponentType<any>) => {
  return FireStormModel("newsItems", (profile) => {
    return (
      profile && {
        collection: Collections.newsItems,
        query: ["ownerId", "==", profile.id],
        // query: ["ownerId", "==", (profile as any).username],
        limit: 20,
        attachName: "newsItems",
        orderBy: {field: "created", direction: "desc"},
      }
    );
  })(WrappedComponent);
};

export interface WithSuggestions {
  suggestions: ModelProps<Suggestion>;
  // saveFoodLog: (document: any) => void;
}

export const withSuggestions = (WrappedComponent: React.ComponentType<any>) => {
  return FireStormModel("suggestions", () => ({
    collection: "suggestions",
  }))(WrappedComponent);
};
