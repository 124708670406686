import moment from "moment-timezone";
import {NewsItem} from "../../Common";
import htmlToText from "html-to-text";

export function getDateString(item: NewsItem): string {
  if (!item || !item.created || !(item as any).created.seconds) {
    return "";
  }
  const date = moment.utc((item as any).created.seconds * 1000);
  if (moment.utc().format("YYYY/MM/DD") === date.format("YYYY/MM/DD")) {
    return `Today, ${date.format("h:mm A")}`;
  } else if (moment.utc().diff(date, "days") < 7) {
    return date.format("ddd, h:mm A");
  } else {
    return date.format("MM/DD/YY h:mm A");
  }
}

export function getSnippet(item: NewsItem, length = 250): string {
  const text = htmlToText.fromString(item.html, {ignoreHref: true, ignoreImage: true});
  let slicedText = "";
  if (text.length > length - 3) {
    let split = text.split(" ");
    let i = 0;
    while (true) {
      if (!slicedText) {
        slicedText = split[i];
      } else {
        slicedText = slicedText + " " + split[i];
      }
      i++;
      if (slicedText.length > length - 3) {
        slicedText = slicedText + " ...";
        break;
      }
    }
  } else {
    slicedText = text;
  }
  return slicedText;
}
