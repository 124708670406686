"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function test() { }
exports.test = test;
exports.Collections = {
    newsItems: "newsItems",
    profiles: "profiles",
};
function getNewId() {
    let text = "";
    const possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 12; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
exports.getNewId = getNewId;
