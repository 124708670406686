import * as React from "react";
import {Profile} from "../../Common";
import {
  Box,
  Button,
  COLOR_MAP,
  Page,
  Text,
  Unifier,
  FireStorm,
  withProfile,
  WithProfileProps,
} from "../../unified";
import {Config} from "./App";

interface Props extends WithProfileProps {
  authStateHandler: () => void;
  componentId: string;
}

interface State {}

class ProfilePageBare extends React.Component<Props, State> {
  static options = {
    layout: {
      backgroundColor: COLOR_MAP["white"],
    },
    topBar: {
      title: {
        text: "Profile",
      },
      visible: true,
    },
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  showFeedback = () => {
    Unifier.utils.openUrl(Config.FEEDBACK_URL);
  };

  showTerms = () => {
    Unifier.utils.openUrl(Config.TERMS_URL);
  };

  showPrivacyPolicy = () => {
    Unifier.utils.openUrl(Config.PRIVACY_POLICY_URL);
  };

  logout = async () => {
    await FireStorm.logout();
    await Unifier.navigation.goToAuth();
  };

  renderLogout() {
    return (
      <Box>
        <Button
          text={`Log Out ${this.props.profile.doc ? this.props.profile.doc.email : ""}`}
          color="red"
          type="solid"
          onClick={this.logout}
        />
      </Box>
    );
  }

  render() {
    const profile = this.props.profile.doc as Profile;

    return (
      <Page componentId={this.props.componentId} color="lightGray">
        <Box
          width="100%"
          display="flex"
          flex="grow"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            maxWidth={400}
            width="100%"
            marginLeft={4}
            marginRight={4}
            paddingY={4}
            paddingX={8}
            color="white"
            shape="rounded"
          >
            <Box paddingY={6}>
              <Text>Your IdleInbox email:</Text>
              <Box paddingY={2}>
                {profile && <Text bold={true}>{profile.username}@idleinbox.com</Text>}
                {!profile && <Text bold={true}>&nbsp;</Text>}
              </Box>
            </Box>

            {this.renderLogout()}
          </Box>
        </Box>
      </Page>
    );
  }
}

export const ProfilePage = withProfile(ProfilePageBare);
