import * as React from "react";
import PropTypes from "prop-types";
import Box from "./Box";
const styles = require("./Image.module.css");

const shouldScaleImage = (fit: any) => fit === "cover" || fit === "contain";

interface Props {
  alt: string;
  children?: React.ReactNode;
  color: string;
  fit: "contain" | "cover" | "none";
  importance?: "high" | "low" | "auto";
  loading?: "eager" | "lazy" | "auto";
  naturalHeight: number;
  naturalWidth: number;
  onError?: () => void;
  onLoad?: () => void;
  sizes?: string;
  src: string;
  srcSet?: string;
}

export default class Image extends React.PureComponent<Props> {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    children: PropTypes.node,
    color: PropTypes.string,
    fit: PropTypes.oneOf(["contain", "cover", "none"]),
    importance: PropTypes.oneOf(["high", "low", "auto"]),
    loading: PropTypes.oneOf(["eager", "lazy", "auto"]),
    naturalHeight: PropTypes.number.isRequired,
    naturalWidth: PropTypes.number.isRequired,
    onError: PropTypes.func,
    onLoad: PropTypes.func,
    sizes: PropTypes.string,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
  };

  static defaultProps = {
    color: "transparent",
    fit: "none",
    importance: "auto",
    loading: "auto",
  };

  componentDidMount() {
    if (shouldScaleImage(this.props.fit)) {
      this.loadImage();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {fit, src} = this.props;
    if (shouldScaleImage(fit) && prevProps.src !== src) {
      this.loadImage();
    }
  }

  handleLoad = () => {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  };

  handleError = () => {
    if (this.props.onError) {
      this.props.onError();
    }
  };

  loadImage() {
    if (typeof window !== "undefined") {
      const image = new window.Image();
      image.onload = this.handleLoad;
      image.onerror = this.handleError;
      image.src = this.props.src;
    }
  }

  render() {
    const {
      alt,
      color,
      children,
      fit,
      importance,
      loading,
      naturalHeight,
      naturalWidth,
      sizes,
      src,
      srcSet,
    } = this.props;

    const isScaledImage = shouldScaleImage(fit);
    const childContent = children ? (
      <Box position="absolute" top left bottom right overflow="hidden">
        {children}
      </Box>
    ) : null;

    return isScaledImage ? (
      <div
        aria-label={alt}
        className={fit === "contain" || fit === "cover" ? styles[fit] : null}
        style={{
          backgroundColor: color,
          backgroundImage: `url('${src}')`,
        }}
        role="img"
      >
        {childContent}
      </div>
    ) : (
      <Box
        position="relative"
        dangerouslySetInlineStyle={{
          __style: {
            backgroundColor: color,
            paddingBottom: `${(naturalHeight / naturalWidth) * 100}%`,
          },
        }}
      >
        <img
          alt={alt}
          className={styles.img}
          // importance={importance}
          loading={loading === "auto" ? undefined : loading}
          onError={this.handleError}
          onLoad={this.handleLoad}
          sizes={sizes}
          src={src}
          srcSet={srcSet}
        />
        {childContent}
      </Box>
    );
  }
}
